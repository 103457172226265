// environment variables are injected at build-time, see .circleci/config.yml

export const API_ORIGIN =
  process.env.REACT_APP_API_ORIGIN || 'https://api.stage.entale.co'
export const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'admin-token'

// TODO: Add new env variables to .circleci/config.yml
export const MEDIA_S3_BUCKET =
  process.env.REACT_APP_MEDIA_S3_BUCKET || 'media.dev.entale.co'
export const MEDIA_S3_REGION =
  process.env.REACT_APP_MEDIA_S3_REGION || 'eu-west-1'
export const FILESTACK_API_KEY =
  process.env.REACT_APP_FILESTACK_API_KEY || 'AscGyniWjQp6KdEwkHoVSz'
