import React from 'react'
import {
    Resource,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    SimpleForm,
    TextInput,
    BooleanField,
    BooleanInput,
    EditButton,
} from 'react-admin'
import ResourceData from './ResourceData'
import { CollectionsArtwork } from '../components/CollectionsArtwork'
import { CollectionsArtworkUploader } from '../components/CollectionsArtworkUploader'
import { API_ORIGIN } from '../config'

export const name = 'discover collections'
export const path = 'v1/curated-collections'

const DiscoverCollectionsContentList = (props: object) => {
    return (
        <List {...props}>
            <Datagrid className="discover-collections-content">
                <TextField source="collectionType" label="Collection Type" />
                <TextField source="collectionUri" label="Collection Uri" />
                <CollectionsArtwork />
                <BooleanField source="isPinned" label="Pinned" />
                <EditButton />
            </Datagrid>
        </List>
    )
}

const DiscoverCollectionsContentEdit = (props: object) => {
    return <Edit {...props} >
        <SimpleForm>
            <TextInput source="collectionType" disabled />
            <TextInput source="collectionUri" disabled />
            <CollectionsArtwork />
            <CollectionsArtworkUploader />
            <BooleanInput source="isPinned" />
        </SimpleForm>
    </Edit>
}

const DiscoverCollectionsContentCreate = (props: object) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="collectionType" defaultValue={'discover'} disabled />
            <TextInput source="collectionUri" />
            <CollectionsArtworkUploader />
            <BooleanInput source="isPinned" defaultValue={false} />
        </SimpleForm>
    </Create>
)

const component = (
    <Resource 
        name={name}
        list={DiscoverCollectionsContentList}
        edit={DiscoverCollectionsContentEdit}
        create={DiscoverCollectionsContentCreate}
        key={name}
    />
)

const formatRequest = (payload: any) => {
    return {
        collectionType: payload.collectionType,
        collectionUri: payload.collectionUri,
        isPinned: payload.isPinned,
        background: payload.background,
    }
}

const formatItemResponse = (item: any) => {
    return { id: item.collectionUri, ...item }
}

const formatSingleResponse = (json: any) => ({ data: formatItemResponse(json) })

const formatMultipleResponse = (json: any) => {
    return {
        data: json.items.map(formatItemResponse),
        total: json.items.length,
    }
}


const formatSingleDeleteRequest = (params: any) => {
    const url = `${API_ORIGIN}/${path}`
    const body = formatRequest(params.previousData)
    
    return {
        url,
        body: JSON.stringify(body)
    }
}

const constructUpdateUrlForId = (id: any) => `${API_ORIGIN}/${path}`

const constructMultipleDeleteUrls = (params: any): Array<string> =>
params.ids.map((id: any) => `${API_ORIGIN}/${path}/discover/${id}`)

export const DiscoverCuratedCollection = new ResourceData({
    name,
    path,
    component,
    formatRequest,
    formatSingleResponse,
    formatMultipleResponse,
    formatSingleDeleteRequest,
    constructUpdateUrlForId,
    constructMultipleDeleteUrls
})
