import React from 'react'
import { CollectionItem } from '../types/CollectionItem'
import { makeMediaURL } from '../utils/makeMediaURL'

interface Props {
    record?: CollectionItem
}

export const CollectionsArtwork: React.FC<Props> = ({ record }) => {
    return record && record.background ? (
        <img 
            alt={record.collectionUri}
            width="168"
            src={makeMediaURL(record.background)}
        />
    ) : (
        <p>-</p>
    )
}