import React from 'react'
import { Resource } from 'react-admin'
import ResourceData from './ResourceData'

export const name = 'shows'
export const path = 'shows'

const component = <Resource name={name} key={name} />

const formatMultipleResponse = (json: any, { filter }: any) => {
  const filteredShows = filter.q
    ? json.filter(({ title }: any) =>
      title.toLowerCase().includes(filter.q.toLowerCase()),
    )
    : json

  return {
    data: filteredShows,
    total: filteredShows.length,
  }
}

export default new ResourceData({
  name,
  path,
  component,
  formatMultipleResponse,
})
