import React from 'react'
import {
  Resource,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
  BooleanField,
  BooleanInput,
} from 'react-admin'
import ResourceData from './ResourceData'
import { PortraitArtwork } from '../components/PortraitArtwork'
import { PortraitArtworkUploader } from '../components/PortraitArtworkUploader'

export const name = 'Carousel Content'
export const path = 'v1/explore/carousel'

const DiscoverCarouselContentList = (props: object) => {
  return (
    <List {...props}>
      <Datagrid className="discover-carousel-content">
        <TextField source="showId" />
        <PortraitArtwork />
        <BooleanField source="isPinned" label="Pinned" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

const DiscoverCarouselContentEdit = (props: object) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="showId" disabled />
      <BooleanInput source="isPinned" defaultValue={false} />
      <PortraitArtwork />
      <PortraitArtworkUploader />
    </SimpleForm>
  </Edit>
)

const DiscoverCarouselContentCreate = (props: object) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="showId" />
      <BooleanInput source="isPinned" defaultValue={false} />
      <PortraitArtworkUploader />
    </SimpleForm>
  </Create>
)

const component = (
  <Resource
    name={name}
    list={DiscoverCarouselContentList}
    edit={DiscoverCarouselContentEdit}
    create={DiscoverCarouselContentCreate}
    key={name}
  />
)

const formatRequest = (payload: any) => {
  return {
    showId: payload.showId,
    isPinned: payload.isPinned,
    portraitArtworkKey: payload.portraitArtworkKey,
  }
}

const formatSingleResponse = (json: any) => ({ data: formatItemResponse(json) })

const formatItemResponse = (item: any) => ({ id: item.showId, ...item })

const formatMultipleResponse = (json: any) => ({
  data: json.map(formatItemResponse),
  total: json.length,
})

export const DiscoverMadeForEntale = new ResourceData({
  name,
  path,
  component,
  formatRequest,
  formatSingleResponse,
  formatMultipleResponse,
})
