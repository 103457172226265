import React from 'react'
import { Admin } from 'react-admin'
import authProvider from './authProvider'
import dataProvider from './dataProvider'
import resources from './resources'

const App = () => (
  <Admin authProvider={authProvider} dataProvider={dataProvider}>
    {resources()}
  </Admin>
)

export default App
