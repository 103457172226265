import React from 'react'

// @ts-ignore
import ReactFilestack from 'filestack-react'
import { FILESTACK_API_KEY, MEDIA_S3_BUCKET, MEDIA_S3_REGION } from '../config'

// prettier-ignore
interface Props {
  uploadPath: string,
  onUploadFinished: (imageKey: string) => void
}

export class ImageUpload extends React.Component<Props> {
  handleUpload({ filesFailed, filesUploaded }: any) {
    if (filesFailed.length > 0) {
      alert('Upload Failed')
    } else {
      this.props.onUploadFinished(filesUploaded[0].key)
    }
  }

  render() {
    console.log(MEDIA_S3_BUCKET, MEDIA_S3_REGION, this.props.uploadPath)
    return (
      <ReactFilestack
        apikey={FILESTACK_API_KEY}
        actionOptions={{
          maxFiles: 1,
          startUploadingWhenMaxFilesReached: true,
          accept: ['image/*'],
          fromSources: ['local_file_system', 'dropbox', 'googledrive'],
          storeTo: {
            location: 's3',
            container: MEDIA_S3_BUCKET,
            region: MEDIA_S3_REGION,
            path: this.props.uploadPath,
            access: 'public',
          },
        }}
        onSuccess={this.handleUpload.bind(this)}
      />
    )
  }
}
