import Publisher from './Publisher'
import OnboardingContent from './OnboardingContent'
import Tags from './Tags'
import Shows from './Show'
import { DiscoverMadeForEntale } from './DiscoverMadeForEntale'
import { TranscriptionJob } from './TranscriptionJob'
import { DiscoverCuratedCollection } from './DiscoverCollections'

import ResourceData from './ResourceData'

const resources: ResourceData[] = [
  Publisher,
  OnboardingContent,
  Shows,
  Tags,
  DiscoverMadeForEntale,
  TranscriptionJob,
  DiscoverCuratedCollection,
]

export const resourceWithName = (name: string) =>
  resources.find((resource: ResourceData) => resource.name === name)

export default () => resources.map(({ component }) => component)
