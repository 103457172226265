import React from 'react'
import {
  Resource,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  SimpleForm,
  TextInput,
} from 'react-admin'
import ResourceData from './ResourceData'

export const name = 'publishers'
export const path = 'publishers'

const PublisherList = (props: object) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="website" />
      <EditButton />
    </Datagrid>
  </List>
)

type PublisherProps = {
  record?: {
    name: string
  }
}

const PublisherTitle = ({ record }: PublisherProps) => {
  return <span>{record ? `"${record.name}"` : 'Unnamed Publisher'}</span>
}

const PublisherEdit = (props: object) => (
  <Edit title={<PublisherTitle />} {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="website" />
    </SimpleForm>
  </Edit>
)

const PublisherCreate = (props: object) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="website" />
    </SimpleForm>
  </Create>
)

const component = (
  <Resource
    name={name}
    list={PublisherList}
    edit={PublisherEdit}
    create={PublisherCreate}
    key={name}
  />
)

export default new ResourceData({
  name,
  path,
  component,
})
