import React from 'react'
import {
  Resource,
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  SimpleForm,
  TextInput,
  NumberInput,
} from 'react-admin'
import ResourceData from './ResourceData'

export const name = 'onboarding content'
export const path = 'v1/onboarding-content'

const OnboardingContentList = (props: object) => (
  <List {...props}>
    <Datagrid>
      <TextField source="show.title" label="Show" />
      <TextField source="shortDescription" />
      <TextField source="episodeId" label="Episode ID" />
      <NumberField source="chapterIndex" label="Chapter Index" />
      <EditButton />
    </Datagrid>
  </List>
)

type TitleProps = {
  record?: {
    show?: {
      title: string
    }
  }
}

const OnboardingContentTitle = ({ record }: TitleProps) => {
  return (
    <span>
      Onboarding Content{' '}
      {record && record.show ? `for "${record.show.title}"` : ''}
    </span>
  )
}

const OnboardingContentEdit = (props: object) => (
  <Edit title={<OnboardingContentTitle />} {...props}>
    <SimpleForm>
      <TextInput label="Show" source="show.title" disabled />
      <TextInput source="shortDescription" />
      <TextInput source="episodeId" label="Episode ID" />
      <NumberInput source="chapterIndex" label="Chapter Index" />
    </SimpleForm>
  </Edit>
)

const OnboardingContentCreate = (props: object) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="showId" label="Show ID" />
      <TextInput source="shortDescription" />
      <TextInput source="episodeId" label="Episode ID" />
      <NumberInput source="chapterIndex" label="Chapter Index" />
    </SimpleForm>
  </Create>
)

const component = (
  <Resource
    name={name}
    list={OnboardingContentList}
    edit={OnboardingContentEdit}
    create={OnboardingContentCreate}
    key={name}
  />
)

const formatMultipleResponse = (json: any) => ({
  data: json.content,
  total: json.content.length,
})

export default new ResourceData({
  name,
  path,
  component,
  formatMultipleResponse,
})
