import { stringify } from 'query-string'
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'
import { name as onboardingResource } from './resources/OnboardingContent'
import { API_ORIGIN, AUTH_TOKEN_LOCAL_STORAGE_KEY } from './config'
import { resourceWithName } from './resources'
import ResourceData from './resources/ResourceData'

type Params = {
  json: any
  id?: string
  ids?: string[]
  data: any
  previousData: any
  pagination: {
    page: number
    perPage: number
  }
  sort: {
    field: string
    order: string
  }
  filter: object
  target: string
}

type Options = {
  headers?: Headers
  method?: string
  body?: string
}

type Response = {
  json: any
}

const httpClient = (url: string, options: Options = {}): Promise<Response> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
  }
  return fetchUtils.fetchJson(url, options)
}

const DISCOVER_COLLECTIONS_BATCH_LIMIT = 100

const convertDataRequestToHTTP = (
  type: string,
  resource: ResourceData,
  params: Params,
) => {
  let url = ''
  const { path } = resource
  const options: Options = {}
  switch (type) {
    case GET_LIST: {
      let query: object
      if (resource.name === 'discover collections') {
        query = {
          collectionType: 'discover',
          limit: DISCOVER_COLLECTIONS_BATCH_LIMIT,
        }
      } else {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(params.filter),
        }
      }
      url = `${API_ORIGIN}/${path}?${stringify(query)}`
      break
    }
    case GET_ONE: {
      url = resource.name == 'discover collections'
        ? `${API_ORIGIN}/${path}/discover/${params.id}`
        : `${API_ORIGIN}/${path}/${params.id}`
      break
    }
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      }
      url = `${API_ORIGIN}/${path}?${stringify(query)}`
      break
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      }
      url = `${API_ORIGIN}/${path}?${stringify(query)}`
      break
    }
    case UPDATE: {
      url = resource.constructUpdateUrlForId(params.id)
      options.method = 'PUT'
      const body = resource.formatRequest(params.data)
      options.body = JSON.stringify(body)
      break
    }
    case CREATE:
      switch (resource.name) {
        case onboardingResource: {
          // TODO: update API to accept `${API_ORIGIN}/${path}`
          url = `${API_ORIGIN}/${path}/${params.data.showId}`
          options.method = 'PUT'
          options.body = JSON.stringify(params.data)
          break
        }
        default: {
          url = `${API_ORIGIN}/${path}`
          options.method = 'POST'
          const body = resource.formatRequest(params.data)
          options.body = JSON.stringify(body)
          break
        }
      }
      break
    case DELETE:
      const { url: deleteUrl, body } = resource.formatSingleDeleteRequest(params)
      url = deleteUrl
      options.method = 'DELETE'
      options.body = body
      break
    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
  return { url, options }
}

const convertHTTPResponse = (
  response: Response,
  type: string,
  resource: ResourceData,
  params: Params,
) => {
  const { json } = response
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      return resource.formatMultipleResponse(json, params)
    case GET_ONE:
    case CREATE:
    case UPDATE:
      return resource.formatSingleResponse(json, params)
    case DELETE:
      return {
        data: {
          id: null, // React admin requires that this field be returned or raises an error...
        },
      }
    default:
      return { data: json }
  }
}

export default (type: string, name: string, params: Params) => {
  const resource = resourceWithName(name)
  if (!resource) {
    return
  }
  console.log(type, { resource: resource.name, params })
  // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  if (type === UPDATE_MANY) {
    if (!params.ids) {
      return
    }
    return Promise.all(
      params.ids.map(id =>
        httpClient(`${API_ORIGIN}/${resource.path}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then(responses => ({
      data: responses.map(response => response.json),
    }))
  }
  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  if (type === DELETE_MANY) {
    if (!params.ids) {
      return
    }
    const urls = resource.constructMultipleDeleteUrls(params)
    return Promise.all(
      urls.map(url => httpClient(url, {
        method: 'DELETE',
      })
      ),
    ).then(responses => ({
      data: responses.map(response => response.json),
    }))
  }

  const { url, options } = convertDataRequestToHTTP(type, resource, params)
  return httpClient(url, options).then(response =>
    convertHTTPResponse(response, type, resource, params),
  )
}
