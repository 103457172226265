import React from 'react'
import { useField } from 'react-final-form'
import { ImageUpload } from './ImageUpload'

export const CollectionsArtworkUploader = () => {
    const name = 'background'
    const {
        input: { onChange },
    } = useField(name)
    return (
        <ImageUpload 
            uploadPath={`/discover/curated-collections/images/`}
            onUploadFinished={onChange}
        />
    )
}