import { API_ORIGIN } from './../config'

interface SingleResponse {
  data: any
}

interface MultipleResponse {
  data: any
  total: number
}

interface SingleDeleteRequest {
  url: string
  body?: any
}

interface ConstructorData {
  name: string
  path: string
  component: React.ReactNode
  formatRequest?: (data: any) => any
  formatSingleResponse?: (json: any) => SingleResponse
  formatMultipleResponse?: (json: any, params: any) => MultipleResponse
  formatSingleDeleteRequest?: (params: any) => SingleDeleteRequest
  constructUpdateUrlForId?: (id: any) => string
  constructMultipleDeleteUrls?: (params: any) => Array<string>
}

export default class ResourceData {
  name: string
  path: string
  component: React.ReactNode
  formatRequest: (data: any) => any
  formatSingleResponse: (json: any, params: any) => SingleResponse
  formatMultipleResponse: (json: any, params: any) => MultipleResponse
  formatSingleDeleteRequest: (params: any) => SingleDeleteRequest
  constructUpdateUrlForId: (id: any) => string
  constructMultipleDeleteUrls: (params: any) => Array<string>

  constructor({
    name,
    path,
    component,
    formatRequest = defaultRequest,
    formatSingleResponse = defaultSingleResponse,
    formatMultipleResponse = defaultMultipleResponse,
    formatSingleDeleteRequest,
    constructUpdateUrlForId,
    constructMultipleDeleteUrls,
  }: ConstructorData) {
    this.name = name
    this.path = path
    this.component = component
    this.formatRequest = formatRequest
    this.formatSingleResponse = formatSingleResponse
    this.formatMultipleResponse = formatMultipleResponse

    this.formatSingleDeleteRequest = formatSingleDeleteRequest || (
      (params) => ({
        url: `${API_ORIGIN}/${path}/${params.id}`
      })
    )

    this.constructUpdateUrlForId = constructUpdateUrlForId || (
      (id: any): string => `${API_ORIGIN}/${path}/${id}`
    )

    this.constructMultipleDeleteUrls = constructMultipleDeleteUrls || (
      (params: any): Array<string> => 
      params.ids.map((id: any) => `${API_ORIGIN}/${path}/${id}`)
    )
  }
}

const defaultRequest = (data: any) => data
const defaultSingleResponse = (json: any): SingleResponse => ({ data: json })
const defaultMultipleResponse = (json: any) => ({
  data: json,
  total: json.length,
})
