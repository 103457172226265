import React from 'react'
import { Resource } from 'react-admin'
import ResourceData from './ResourceData'

export const name = 'tags'
export const path = 'v1/tags'

const component = <Resource name={name} key={name} />

const formatSingleResponse = (json: any) => ({ data: json.tag })
const formatMultipleResponse = (json: any) => ({
  data: json.tags,
  total: json.tags.length,
})

export default new ResourceData({
  name,
  path,
  component,
  formatSingleResponse,
  formatMultipleResponse,
})
