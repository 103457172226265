import React from 'react'
import { useField } from 'react-final-form'
import { ImageUpload } from './ImageUpload'

export const PortraitArtworkUploader = () => {
  const name = 'portraitArtworkKey'
  const {
    input: { onChange },
  } = useField(name)
  return (
    <ImageUpload
      uploadPath={`/discover/made-for-entale/images/`}
      onUploadFinished={onChange}
    />
  )
}
