import React from 'react'
import { CarouselItem } from '../types/CarouselItem'
import { makeMediaURL } from '../utils/makeMediaURL'

interface Props {
  record?: CarouselItem
}

export const PortraitArtwork: React.FC<Props> = ({ record }) => {
  return record && record.portraitArtworkKey ? (
    <img
      alt={record.showId}
      width="112"
      src={makeMediaURL(record.portraitArtworkKey)}
    />
  ) : (
      <p>—</p>
    )
}
