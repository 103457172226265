import React from 'react'
import {
  Resource,
  List,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  SelectInput,
  BooleanInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin'
import ResourceData from './ResourceData'

export const name = 'Transcription Job'
export const path = 'v1/transcriptions'

const TranscriptionJobList = (props: object) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="operationId" />
        <TextField source="showId" />
        <TextField source="episodeId" />
        <TextField source="failures" />
      </Datagrid>
    </List>
  )
}

const TranscriptionJobCreate = (props: object) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="showId"
        label="External ShowID"
        helperText="eg. itunes:1493065330"
      />
      <TextInput
        source="episodeId"
        label="Episode GUID (Optional)"
        helperText="(Default) Attempts to transcribe latest episode in feed"
      />
      <h4>Transcription paramaters</h4>
      <p>
        <a
          href="https://cloud.google.com/speech-to-text/docs/reference/rest/v1p1beta1/RecognitionConfig"
          target="_BLANK"
          rel="noopener noreferrer"
        >
          https://cloud.google.com/speech-to-text/docs/reference/rest/v1p1beta1/RecognitionConfig
        </a>
      </p>
      <TextInput
        multiline
        source="speech.speechContexts"
        label="speechContexts"
      />
      <SelectInput
        source="speech.model"
        label="model"
        initialValue="video"
        choices={[
          {
            id: 'video',
            name: 'Video',
          },
          {
            id: 'default',
            name: 'Default',
          },
        ]}
      />
      <BooleanInput
        source="speech.useEnhanced"
        label="useEnhanced"
        initialValue={true}
      />
      <BooleanInput
        source="speech.enableWordTimeOffsets"
        label="enableWordTimeOffsets"
        initialValue={true}
      />
      <BooleanInput
        source="speech.enableWordConfidence"
        label="enableWordConfidence"
        initialValue={false}
      />
      <BooleanInput
        source="speech.enableAutomaticPunctuation"
        label="enableAutomaticPunctuation"
        initialValue={true}
      />
      <TextInput
        source="speech.maxAlternatives"
        label="maxAlternatives"
        initialValue={1}
        helperText="Valid values are 0-30"
      />
      <BooleanInput
        source="speech.diarizationConfig.enableSpeakerDiarization"
        label="enableSpeakerDiarization"
        initialValue={true}
      />
      <TextInput
        source="speech.diarizationConfig.minSpeakerCount"
        label="minSpeakerCount"
        initialValue={2}
      />
      <TextInput
        source="speech.diarizationConfig.maxSpeakerCount"
        label="maxSpeakerCount"
        initialValue={6}
      />
      <TextInput
        source="speech.languageCode"
        initialValue="en-US"
        label="languageCode"
        helperText="A BCP-47 language tag. Example: 'en-US'"
      />
      <ArrayInput
        source="speech.alternativeLanguageCodes"
        label="alternativeLanguageCodes"
      >
        <SimpleFormIterator>
          <TextInput initialValue="en-GB" label="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)

const component = (
  <Resource
    name={name}
    list={TranscriptionJobList}
    create={TranscriptionJobCreate}
    key={name}
  />
)

const formatRequest = (payload: any) => {
  const request = {
    showId: payload.showId,
    episodeGuid: payload.episodeId,
    speech: payload.speech,
  }
  try {
    request.speech.speechContexts = JSON.parse(payload.speech.speechContexts)
  } catch {
    alert('Unable to parse speechContext input as JSON')
  }
  return request
}

const formatSingleResponse = (json: any) => ({ data: formatItemResponse(json) })

const formatItemResponse = (item: any) => ({ id: item.showId, ...item })

const formatMultipleResponse = (json: any) => ({
  data: json.map(formatItemResponse),
  total: json.length,
})

export const TranscriptionJob = new ResourceData({
  name,
  path,
  component,
  formatRequest,
  formatSingleResponse,
  formatMultipleResponse,
})
