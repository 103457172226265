import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import { API_ORIGIN, AUTH_TOKEN_LOCAL_STORAGE_KEY } from './config'

async function authenticate(email: string, password: string) {
  const response = await fetch(`${API_ORIGIN}/auth`, {
    method: 'post',
    body: JSON.stringify({ email, password }),
  })

  if (response.ok) {
    const { token } = await response.json()
    return token
  } else {
    throw new Error('Failed to authenticate with API')
  }
}

export default async (type: string, params: any) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username: email, password } = params
    const token = await authenticate(email, password)
    localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token)
    return
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
    return
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
      throw new Error()
    }
    return
  }

  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
    if (token) {
      return token
    } else {
      throw Error('token not found')
    }
  }

  // we don't know what we're doing
  throw new Error('Unknown method')
}
